<template>
  <div>
    <main-hero
      ref="hero"
      title="Hae yrityslainaa vakuudettomana kuluttajalainana"
      subtitle="Kilpailuta ja vertaile kuluttajalaina yrityksellesi"
      side-title="Täytä lainahakemus tästä"
      :hero-background="require('~/assets/img/business-hero-bg.jpg')"
      :show-the-girl="false"
      :side-boxes="topPoints"
      :breadcrumb="{'Yrityslainat': ''}"
    >
      <lazy-hydrate when-idle>
        <loan-application />
      </lazy-hydrate>
    </main-hero>
    <section class="hero has-background-rlgray">
      <div class="hero-body">
        <div class="container">
          <h2 class="title has-text-white is-size-4 has-text-centered">
            Kilpailuta lainat ja valitse sopivin
          </h2>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <lazy-hydrate when-visible>
          <loan-steps />
        </lazy-hydrate>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <div class="content">
          <h1>Yrityslaina - Kuluttajalaina toimii apuna yrityksen perustamiseen tai kasvattamiseen</h1>
          <p>
            Yrittäjyydestä haaveileminen voi olla jännittävä matka, joka tarjoaa mahdollisuuden toteuttaa oma visio
            ja rakentaa menestyvä liiketoiminta. Yksi tapa rahoittaa yrityksen perustamista on kuluttajalaina.
            Kuluttajaluoton avulla voit saada tarvitsemasi rahoituksen nopeasti ja käyttää sitä yrityksen perustamiseen
            ja kasvattamiseen.
          </p>
          <p>
            Tältä sivulta löydät runsaasti hyödyllistä tietoa siitä, miten kuluttajalaina voi auttaa sinua perustamaan
            oman yrityksen. Vaihtoehtoisesti kuluttajaluottona tarjottavaa yrityslainaa voidaan käyttää myös yrityksen
            kasvattamiseen tai väliaikaisen kassavajeen paikkaamiseen.
          </p>
          <h2>Yrityslaina uudelle yritykselle</h2>
          <p>
            Haet yrityslainaa uudelle yritykselle tai toiminimelle kätevästi Rahalaitokselta. Olipa kyseessä sitten
            yrityslaina toiminimelle tai osakeyhtiölle, on kulutusluottoa järkevää harkita yrityslainanaksi.
          </p>
          <p>
            Kauttamme kilpailutat yhdellä hakemuksella usean suomalaisen ja pohjoismaisen pankin sekä luotontarjoajan.
            Tehtyäsi hakemuksen saat lainapäätöksen ja ensimmäiset tarjoukset välittömästi. Voit tarkastella
            ja vertailla jokaisen saamasi yrityslainatarjouksen Oma Rahalaitos -palvelussa.
          </p>
          <h2>Kuluttajaluotossa paljon etuja verrattuna tavalliseen yrityslainaan</h2>
          <p>
            Kun mietit kuluttajalainaa vaihtoehtona yritysrahoitukseksi on kyseessä potentiaalinen vaihtoehto
            muun muassa seuraavista syistä:
          </p>
          <p>
            <b>Rahoituksen saaminen on nopeaa ja helppoa</b><br>
            Yksi suurimmista eduista kuluttajalainan käytössä yrityksen perustamiseen on rahoituksen hakemisen ja
            saamisen nopeus. Perinteisten liiketoimintalainojen saaminen voi olla hidasta ja monimutkaista, kun taas
            kuluttajalainan hakeminen voi olla nopeaa ja vaivatonta.
          </p>
          <p>
            Mikäli sinulla on tarve löytää yrityslaina heti, kokeile Rahalaitoksen palvelua. Sivuiltamme löytyvän
            hakemuksen täyttämiseen kuluu aikaa vain muutama minuutti ja hakemuksen lähetyksen jälkeen me kilpailutamme
            sen useilla lainakumppaneillamme. Saat lainapäätöksen ja ensimmäiset tarjoukset jopa saman tien.
          </p>
          <p>
            <b>Voit perustaa yrityksen ilman suurta pääomaa</b><br>
            Toinen merkittävä etu kuluttajalainan käytössä yrityksen perustamisessa on se, että voit aloittaa
            ilman suurta omaisuutta. Perinteisesti yrityslainat edellyttävät usein yrityksen omistajalta vakuuksia
            tai takauksia. Kuluttajalainan avulla voit sen sijaan käyttää vakuutenahenkilökohtaista luottohistoriaasi
            ja tulotietojasi lainan saamiseen, mikä helpottaa erityisesti aloittelevia yrittäjiä saamaan rahoitus
            yritykselle.
          </p>
          <p>
            <b>Kuluttajalainaa voit käyttää joustavasti kaikkiin yrityksesi tarpeisiin</b><br>
            Kuluttajalainan avulla saatavat varat ovat joustavasti käytettävissäsi yrityksesi tarpeiden mukaan. Kyseistä
            lainaa voi käyttää niin alkupääoman saamiseen, tuotteiden tai palveluiden hankintaan, markkinointiin kuin
            muihinkin liiketoiminnan kustannuksiin. Koska kyseessä on henkilökohtainen yrityslaina, on sinulla vapaus
            päättää, miten käytät rahoituksen yrityksesi kehittämiseen.
          </p>
          <p>
            <b>Kulutusluotolla rakennat hyvän luottoluokituksen yrityksellesi</b><br>
            Käyttämällä kuluttajalainaa yrityksen perustamiseen, sinulla on mahdollisuus rakentaa hyvä luottoluokitus
            yrityksellesi. Kun maksat lainan takaisin ajallaan ja noudatat sovittuja maksuehtoja, luot rahoituslaitosten
            ja muiden sidosryhmien välille luottamusta. Tämä voi helpottaa sinua tulevaisuudessa saamaan entistä
            parempia lainaehtoja ja muita rahoitusratkaisuja yrityksesi kasvattamisen tueksi.
          </p>
          <h3>Kuluttajaluotto yrityslainana kääntää riskit henkilökohtaiseksi</h3>
          <p>
            Kun harkitset kuluttajalainan ottamista yrityslainaksi, on tärkeää ottaa huomioon joitakin riskejä, jotka
            liittyvät tähän päätökseen. Tässä on muutamia yleisiä huomioon otettavia asioita:
          </p>
          <p>
            <b>Lainan vastuu on henkilökohtainen</b><br>
            Vakuudeton yrityslaina kuluttajaluottona perustuu lainanottajan henkilökohtaiseen luotto korko
            ja lainapäätöksen saanti perustuu yleensä henkilökohtaiseen luottoluokitukseen ja takaisinmaksukykyyn.
            Pankit ja rahoituslaitokset käyttävät näitä arvioidessaan lainapäätöstä sekä lainan korkoa.
          </p>
          <p>
            Tämä tarkoittaa myös sitä, että lainanottaja on henkilökohtaisesti vastuussa lainan takaisinmaksusta, vaikka
            se käytettäisiinkin yritystoiminnan rahoittamiseen. Yrityksen kohdatessa taloudellisia vaikeuksia, se voi
            vaikuttaa myös henkilökohtaiseen talouteesi.
          </p>
          <p>
            <b>Kuluttajalainalla saattaa olla korkeammat kustannukset</b><br>
            Koska kuluttajalaina on vakuudeton, voi sen korkoprosentti olla korkeampi verrattuna tavallisen yrityslainan
            korkoon. Tämä voi tarkoittaa korkeampia kustannuksia lainan takaisinmaksussa ja näin vaikuttaa yrityksen
            kannattavuuteen.
          </p>
          <p>
            <b>Kuluttajalainan lainasummat eivät vastaa perinteisten yrityslainojen summaa</b><br>
            Kuluttajalainat ovat yleensä suhteellisen pieniä summia verrattuna suuriin yrityslainoihin.
            Tämä voi rajoittaa yrityksesi mahdollisuuksia rahoittaa suuria investointeja tai kasvuhankkeita
            Jos tarvitset merkittäviä määriä rahoitusta, saattaa olla tarpeen harkita vaihtoehtoisia
            rahoitusvaihtoehtoja.
          </p>
          <p>
            Ennen yrityslainan hakemista onkin tärkeää arvioida huolellisesti yllämainitut riskit ja harkita ennen kuin
            päätät ottaa kuluttajalainan yritystoiminnan rahoittamiseen. Rahalaitoksen kautta kilpailutat kuitenkin
            yrityslainaa kulutusluottona aina 60 000 euroon asti. Tämä riittää usein pienten yritysten tai toiminimen
            perustamiseen.
          </p>
          <h2>Suunnittele aluksi kuinka paljon yrityslainaa tarvitset yrityksen perustamiseen</h2>
          <p>
            Yrityksen perustaminen on jännittävä ja monivaiheinen prosessi, johon liittyy erilaisia taloudellisia
            näkökohtia. Kyseessä on kriittinen aika, jolloin tarvitaan riittävästi rahoitusta varmistaakseen yrityksen
            menestyminen ja kasvu.
          </p>
          <p>
            Rahoituksen hankkiminen alkuvaiheeseen on tärkeää, sillä sen avulla katetaan erilaisia kuluja ja
            mahdollistetaan liiketoiminnan aloitus ja kehittäminen sujuvasti. Ota seuraavat seikat huomioon kun lasket
            kuinka paljon tarvitset yrityslainaa heti alussa:
          </p>
          <p>
            <b>Käynnistyskustannukset:</b> Uuden yrityksen perustamisessa syntyy monia kustannuksia. Tilojen vuokraus,
            laitteiden ja varaston hankinta, oikeudelliset kulut, rekisteröintimaksut sekä markkinointi ovat kaikki
            menoeriä, joita tulisi miettiä jo heti alkuvaiheessa. Oman pääoman sekä yritysrahoituksen avulla yrityksen
            olisi hyvä pystyä kattamaan nämä alkuinvestoinnit ja varmistamaan, että toiminnan aloittamiselle on kaikki
            tarvittavat resurssit.
          </p>
          <p>
            <b>Tuotekehitys ja tutkimus:</b> Uuden yrityksen menestyksen avain on monissa tapauksissa kilpailukykyinen
            tuote tai palvelu. Yrityslainan avulla saat apua tuotteen tai palvelun tutkimiseen, testaamiseen sekä
            kehittämiseen. Näin varmistetaan houkuttelevan ja toimivan tuotteen tarjoaminen markkinoille.
          </p>
          <p>
            <b>Palkat ja palkkiot:</b> Mikäli kyseessä ei ole yrityslaina toiminimelle, on yrityksessä usein muutakin
            henkilöstöä perustajan lisäksi. Yrityksen perustamisvaiheessa onkin tärkeää pystyä houkuttelemaan ja
            palkkaamaan ammattitaitoista henkilöstöä, auttamaan yritystä saavuttamaan tavoitteensa. Yrityslainaa
            voidaankin käyttää myös palkanmaksuun.
          </p>
          <p>
            <b>Markkinointi ja myynti:</b> Yritykselle on erittäin tärkeää pystyä asiakkaiden tavoittaminen ja brändin
            rakennus. Yritysrahoituksen avulla toteutat markkinointi- ja myyntikampanjoita, kuten mainontaa,
            markkinatutkimuksia, verkkosivuston kehittämistä ja markkinointimateriaalien luomista. Näin kasvatat
            yrityksen tietoisuutta markkinoilla ja houkuttelet asiakkaita.
          </p>
          <p>
            <b>Toimintakassan turvaaminen:</b> Alkuvaiheessa monikaan yritys ei heti saavuta kannattavuutta. Tällöin
            yritysrahoituksella voidaan ylläpitää yrityksen toimintakassaa, joka auttaa selviytymään mahdollisista
            kassavirtahaasteista. Näin varmistetaan, että yritys voi jatkaa toimintaansa ja kasvaa pitkällä
            aikavälillä.
          </p>
          <p>
            On suositeltavaa tehdä huolellinen taloudellinen suunnittelu ja budjetointi huolella ennen yrityksen
            perustamista. Tämä auttaa sinua ymmärtämään yrityksen rahoitustarpeet ja varmistamaan, että sinulla on
            riittävästi resursseja menestyvän yrityksen rakentamiseen ja liiketoiminnan käynnistämiseen.
          </p>
          <h2>Myös yrityksen kasvattamiseen voidaan tarvita yrityslainaa</h2>
          <p>
            Kun yritys tavoittelee kasvua, rahoituksen merkitys kasvaa entisestään. Kasvun tukeminen edellyttää usein
            lisäresursseja ja investointeja eri osa-alueille. Koska toiminta laajentuu on myös otettava huomioon useita
            seikkoja, jotka voivat mahdollistaa tämän.
          </p>
          <p>
            Rahoituksen avulla yritys voi toteuttaa kasvustrategiansa ja varmistaa, että sillä on tarvittavat resurssit
            onnistuneeseen kasvuun. Tällöin on taas hyvä miettiä mihin kasvuvaiheessa oleva yritys saattaa tarvita
            lisärahoitusta:
          </p>
          <p>
            <b>Laajentuminen:</b> Kun yritys haluaa kasvaa, se saattaa tarvita yritysrahoitusta laajentuakseen uusille
            markkinoille tai alueille. Tämä voi sisältää uusien toimipisteiden avaamista, tuotannon kasvattamista
            tai jopa kansainvälistymistä. Rahoituksen avulla yritys voi paremmin kattaa laajentumisesta aiheutuvat
            kulut, kuten tilojen vuokrat, lisääntyneet varasto- ja tuotantokustannukset sekä markkinointi- ja
            myyntitoimenpiteet uusilla markkinoilla.
          </p>
          <p>
            <b>Tuotekehitys ja innovaatio:</b> Kasvun tukeminen edellyttää usein tuotekehitystä ja innovaatiota. Jotta
            yritys pysyy kilpailukykyisenä, tulee sen kehittää joko uusia tuotteita tai palveluita tai kehittää jo
            olemassaolevia. Yrityslainaa voidaan investoida tutkimukseen ja kehitykseen, prototyyppien luomiseen,
            testaukseen ja tuoteinnovaatioihin. Tämä antaa yritykselle kilpailuetua ja auttaa sen kasvattamaan
            markkinaosuuttaan.
          </p>
          <p>
            <b>Henkilöstön palkkaaminen ja kouluttaminen:</b> Kasvaessaan yritys tarvitsee usein lisää työvoimaa.
            Ammattimaisten työntekijöiden palkkaaminen ja kouluttaminen ei ole ilmaista, mutta keskeistä yrityksen
            kehitykselle ja kilpailukyvyn ylläpitämiselle.
          </p>
          <p>
            <b>Markkinointi ja myynti:</b> Kasvuvaiheessa olevan yrityksen on tärkeää investoida markkinointiin ja
            myyntiin. Rahoituksella voidaan mahdollistaa tehokkaiden markkinointikampanjoiden toteuttamisen, jotka
            saavuttavat entistä isomman yleisön. Lisäksi rahaa tarvitaan brändin jatkuvaan rakentamiseen,
            markkinointitutkimusten tekemiseen sekä myyntitiimien vahvistamisen. Näiden investointien avulla yritys
            voi kasvattaa asiakaskuntaansa, saada lisää tilauksia ja parantaa myyntiään.
          </p>
          <p>
            <b>Teknologia ja infrastruktuuri:</b> Kasvaessaan yritys voi nähdä tarpeelliseksi päivittää tai laajentaa
            teknologiaa ja infrastruktuuria. Investoimalla uusiin järjestelmiin, laitteisiin ja teknologisiin
            ratkaisuihin,voidaan tehostaa liiketoimintaprosesseja, parantaa tuottavuutta ja mahdollistavaa kasvu.
          </p>
          <p>
            Näiden tekijöiden lisäksi on tärkeää ottaa huomioon myös yllättävät tai odottamattomat kulut, joihin
            voi kuulua esimerkiksi lisensointi- tai patenttimaksuja, vakuutusmaksuja, kirjanpitopalveluja tai
            ammatillista neuvontaa.
          </p>
          <p>
            On tärkeää huomata, että kasvun tukemiseen liittyvät kustannukset voivat vaihdella yrityksen koosta,
            toimialasta ja kasvustrategiasta riippuen. Siksi on tärkeää tehdä perusteellinen budjetointi ja suunnittelu
            kasvun rahoittamiseksi sekä pitää huolta siitä, että taloudelliset resurssit ovat riittävät yrityksen
            tarpeisiin.
          </p>
          <h2>Yrityslainalaskuri</h2>
          <p>
            Kokeile yrityslainalaskuria löytääksesi itsellesi sopiva yrityslainavaihtoehto. Laske ensin tarkkaan
            kuinka paljon lainaa tarvitset perustaaksesi tai kasvattaaksesi yritystäsi. Tämän jälkeen mieti
            lainalaskurin avulla kuinka paljon tarvitset takaisinmaksuaikaa lainallesi.
          </p>

          <p>
            Paras yrityslaina ei välttämättä tarkoita pisintä maksuaikaa. Vaikka kuukausierä onkin pidemmillä
            laina-ajoilla pienempi, on lainan kokonaiskustannukset aina sitä suuremmat, mitä pidempään lainaa maksat.
            Suunnittele siis huolella lainalaskurin avulla talouteesi sopivat maksuerät ennen lainan ottamista.
          </p>
          <h2>Yrityslaina ilman vakuuksia</h2>
          <p>
            Rahalaitoksen kautta kilpailutat kuluttajalainan yritystäsi varten aina ilman vakuuksia. Et myöskään
            tarvitse takaajaa lainallesi, vaan lainan vakuutena on ainoastaan vakaa taloudellinen tilanteesi. Sinun
            on mahdollista hakea yrityslainaa ilman vakuuksia täyttämällä seuraavat ehdot:
          </p>
          <ul>
            <li>Olet yli 20-vuotias</li>
            <li>Sinulla on säännölliset palkka- tai eläketulot</li>
            <li>Sinulla ei ole maksuhäiriömerkintöjä</li>
          </ul>
          <h2>Yrityslainan korko</h2>
          <p>
            Yrityslainan korko määräytyy samalla tavalla kuin muissakin kulutusluotoissa. Pankki määrittää ensin
            lainalle viitekoron. Viitekorkona käytetään joko Euromaiden yhteistä euriborkorkoa tai pankin itse
            määrittelemää prime korkoa.
          </p>
          <p>
            Tämän lisäksi korkoon lisätään marginaali, jonka pankki määrittelee tulojesi ja maksuhistoriasi mukaan.
            Näin ollen yrityslainan marginaali tekee jokaisesta lainatarjouksesta henkilökohtaisen ja se vaihtelee
            pankeittain. Suuntaa antavan arvion yrityslainan korosta voit kuitenkin katsoa lainalaskurin avulla
            valitsemalla tarvitsemasi lainasumma ja sopiva takaisinmaksuaika. Yrityslainan marginaali taas on eriteltynä
            ja näkyvissä jokaisessa saamassasi lainatarjouksessa.
          </p>
          <h2>Hae yrityslainaa</h2>
          <p>
            Löytääksesi paras yrityslaina sinun kannattaa tehdä lainahakemus netissä. Se on nopeaa ja parhaimman
            tarjouksen löytäminen helppoa kun vertailtavia tarjouksia saa kerralla useampia. Yrityslainan hakeminen
            ilman reaalivakuuksia onnistuu Rahalaitoksen palvelussa täyttämällä hakemus, johon tarvitaan vain
            lainanottajan henkilökohtaiset tiedot.
          </p>
          <p>
            Yrityslainasi kilpailutat sujuvasti ja kattavasti Rahalaitoksen palvelussa aina ilman vakuuksia tai
            takaajia. Kun täytät hakemuksen, saat välittömästi lainapäätöksen sekä ensimmäiset tarjoukset. Useat
            yhteistyöpankkimme käyttävät automatisoitua hakuprosessia, joka takaa, että seuraavatkin tarjoukset tulevat
            yleisesti ottaen nähtäväksesi todella nopeasti.
          </p>
          <p>
            Tarjousten saapuessa voit siirtyä Oma Rahalaitos -palveluun, jossa vertailet sujuvasti ja läpinäkyvästi
            kaikkien sinulle tarjottujen yrityslainojen korkoja sekä lainaehtoja. Sopivimman lainan saat valita itse
            lukuisten vaihtoehtojen joukosta.
          </p>
          <h2>Paras yrityslaina löytyy vain vertailemalla</h2>
          <p>
            Yrityslainojen vertailu on hyvä keino säästää lainakuluissa. Kilpailuttamalla lainan ja vertailemalla
            tarjouksia voit säästää lainakustannuksissa jopa tuhansia euroja. Palvelumme avulla säästät myös aikaasi,
            kun etsimme puolestasi yrityksellesi edullisen lainan. Rahalaitos on puolueeton toimija ja meille
            tärkeintä on löytää lainanhakijoillemme paras mahdollinen laina.
          </p>
          <p>
            Huomaa myös, että vaikka haet yrityslainaa toiminimelle tai yritykselle, on kyseessä kuluttajalaina.
            Näin ollen voit myös hakea luottoa yhteishakijan kanssa, kuten puolisosi. Tämä voi parantaa huomattavasti
            pankkien tarjouksia, sillä heidän riski saada laina maksetuksi takaisin pienenee.
          </p>
          <p>
            Miettiessäsi parasta vaihtoehtoa yrityslainaksi muista myös, että lainahakemuksen täyttäminen ja
            yrityslainojen kilpailuttaminen kauttamme on aina täysin ilmaista. Kilpailutus ei myöskään sido sinua
            ottamaan
            lainaa. Näin ollen kilpailuttaminen kauttamme kannattaa aina.
          </p>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <lazy-hydrate when-visible>
          <banks />
        </lazy-hydrate>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <lazy-hydrate when-visible>
          <faq :questions="questions" />
        </lazy-hydrate>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <lazy-hydrate when-visible>
          <subpages />
        </lazy-hydrate>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <lazy-hydrate when-visible>
          <terms loan-type="consumer" />
        </lazy-hydrate>
      </div>
    </section>
    <hero-action />
  </div>
</template>

<script>

import LazyHydrate from 'vue-lazy-hydration'

export default {
  components: {
    LazyHydrate,
    loanApplication: () => import( '~/components/loanApplication' ),
    faq: () => import( '~/components/layout/business/faq' ),
    banks: () => import( '~/components/layout/banks' ),
    heroAction: () => import( '~/components/layout/heroAction' ),
    loanSteps: () => import( '~/components/layout/business/loanSteps' ),
    mainHero: () => import( '~/components/layout/mainHero' ),
    subpages: () => import( '~/components/layout/subpages' ),
    terms: () => import( '~/components/layout/terms' )
  },
  data () {
    return {
      topPoints: [
        {
          icon: () => import( '~/assets/img/icons/scale.svg?inline' ),
          title: 'Kilpailuta ja',
          subtitle: 'vertaile'
        },
        {
          icon: () => import( '~/assets/img/icons/time.svg?inline' ),
          title: 'Nopeasti ja',
          subtitle: 'ilmaiseksi'
        },
        {
          icon: () => import( '~/assets/img/icons/money.svg?inline' ),
          title: 'Vakuudetonta lainaa jopa',
          subtitle: '60 000 €'
        }
      ]
    }
  },
  async fetch ( { store } ) {
    await store.dispatch( 'subpage/setPages', [
      {
        title: 'Kevätaurinko kurkkii',
        subtitle: 'Säästää kesähankinnoissa',
        link: '/talous/katso-vinkit-kesahankintoihin',
        image: '/cms/uploads/large_richard_ciraulo_o_F3_Rbiki6_HQ_unsplash_1b349b1b35.jpg'
      },
      {
        title: 'Yhdistä lainasi',
        subtitle: 'Selkiytä talouttasi ja säästä kuluissa',
        link: '/yhdista-lainat'
      },
      {
        title: 'Kilpailuta pohjoismaiset pankit',
        subtitle: 'Vertaile ja katso mistä saat edullisimman lainan',
        link: '/talous/suomalainen-ruotsalainen-vai-norjalainen-pankki',
        image: '/cms/uploads/large_photo_1538332576228_eb5b4c4de6f5_3bdf11ac3a.jpg'
      }
    ] )
  },
  head () {
    return {
      title: 'Yrityslaina - Kilpailuta yrityslaina | Rahalaitos',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: `Tarvitsetko yrityslainaa? Rahalaitos kilpailuttaa yrityslainat puolestasi täysin ilmaiseksi! Meidän
            avulla löydät edullisimman yrityslainan. Hae jo tänään!`
        }
      ],
      link: [
        { rel: 'canonical', href: 'https://www.rahalaitos.fi/yrityslainat' }
      ]
    }
  },
  methods: {
    scrollToHero () {
      this.$refs.hero.$el.scrollIntoView( {
        behavior: 'smooth',
        block: 'start'
      } )
    }
  }
}
</script>
