<template>
  <div class="content">
    <breadcrumb
      :path="{'Yhdistä lainat': '/yhdista-lainat', 'Petri yhdisti pienet lainansa': ''}"
    />
    <div class="subpage_floating">
      <img :src="require(`~/assets/img/subpage/${this.$route.path.substring(1)}.jpg`)" :alt="this.$route.path.substring(1)">
      <sliders class="is-hidden-touch" />
    </div>
    <h1>Petri yhdisti pienet lainansa ja säästää nyt 263 euroa kuussa</h1>
    <h5>Monet suomalaiset ovat saaneet pienennettyä lainojensa korkoja ja kuluja.</h5>
    <p>
      Raha on yksi yleisimmistä huolenaiheistamme, ja mielessä lähes koko ajan. Täysin eroon rahahuolista on varmaan lähes mahdotonta päästä, mutta on olemassa pieniä keinoja, joilla omaa taloustilannetta pystyy helpottamaan.
    </p>
    <sliders class="is-hidden-desktop" />
    <p>
      Yksi tapa säästää kymmeniä, jopa satoja euroja kuussa, on kilpailuttaa kotitalouden lainat.
    </p>
    <p>
      Asuntolainan kilpailuttaminen onkin monelle tuttua, mutta aivan samalla tavalla on mahdollista kilpailuttaa myös pienet kulutusluotot, osamaksut ja luottokorttivelat.
    </p>
    <p>
      <nuxt-link to="/"> Suomalainen lainanvälittäjä Rahalaitos </nuxt-link> auttaa pienentämään lainojen korkoja ja kuluja kahdella tavalla.
    </p>
    <p>
      Ensinnäkin, se ei tyydy antamaan vain yhtä lainatarjousta, vaan se kilpailuttaa lainahakemuksen jopa 25 yhteistyöpankillaan. Näin voit saada yhdellä hakemuksella useita lainatarjouksia, ja valita niistä rauhassa parhaan.
    </p>
    <p>
      Toiseksi, Rahalaitos sen kautta voi yhdistää useat pienet lainat yhdeksi isommaksi, jolloin palvelumaksut vähenevät, ja korko pienenee.
    </p>
    <h2>Satojen eurojen säästöt</h2>
    <p>
      Yksi Rahalaitoksen tyytyväisistä asiakkaista on Petri. Hänelle oli kertynyt viisi kulutusluottoa, joiden summa oli yhteensä hieman alle 20 000 euroa ja maksuaika keskimäärin viisi vuotta. Näiden lainojen kuukausierät olivat yhteensä 714 euroa ja keskimääräinen korko hieman alle 40 prosenttia.
    </p>
    <p>
      Kun hän yhdisti kulutusluotot yhdeksi lainaksi samalla viiden vuoden maksuajalla, putosi kuukausierä 451 euroon ja korko noin 18 prosenttiin.
    </p>
    <p>
      Lainojen yhdistämisen ansiosta Petrille jää joka kuukausi käteen 263 euroa enemmän. Vuoden aikana se tekee 3 156 euroa enemmän rahaa. Lainan korko puolestaan putosi alle puoleen aiemmasta.
    </p>
    <p>
      Rahalaitoksen kautta voi hakea lainaa 500-60 000 euroa. Riittää, että täytät hakemuksen, jonka jälkeen pääset kirjautumaan Oma Rahalaitos -palveluun. Sinne saat kootusti lainatarjoukset eri pankeilta. Jos hyväksyt jonkun tarjouksista, saat rahat tilillesi yleensä parissa päivässä, joskus jopa saman päivän aikana.
    </p>
    <p>
      <nuxt-link to="/"> Haluaisitko säätää jopa satoja euroja kuussa? Täytä hakemus ja katso paljonko sinä voit säästää. </nuxt-link>
    </p>
    <p>
      <i>Tiinan ja Petrin nimet on muutettu.</i>
    </p>

    <share text="Älä hae lainaa mistä tahansa" />
  </div>
</template>

<script>
import sliders from '~/components/subpage/sliders'
import share from '~/components/subpage/share'

export default {
  layout: 'articles',
  name: 'AlaHaeLainaaMistaTahansa',
  components: {
    sliders,
    share,
    breadcrumb: () => import( '~/components/breadcrumb' )
  },
  head () {
    return {
      title: 'Petri yhdisti pienet lainansa ja säästää nyt 263 euroa kuussa',
      meta: [
        { hid: 'description', name: 'description', content: 'Petri yhdisti luotot yhdeksi lainaksi viiden vuoden maksuajalla, ja sen myötä kuukausierä putosi 451 euroon ja korko noin 18 prosenttiin.' }
      ]
    }
  }
}
</script>
